import { ENV, TENANT_DOMAIN_INDEX } from '../config/envConfig';

export const getBackUrl = (searchParams: URLSearchParams) => {
  return window.location?.pathname + `?${searchParams.toString()}`;
};

export const getBackUrlParamString = (searchParams: URLSearchParams) => {
  const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
  const params = new URLSearchParams({ backUrl });
  const paramString = params.toString();

  return paramString;
};

export const getTenantIdAndBusinessGroupIdFromUrl = () => {
  //!development override
  const url = `${window.location.host}`.replace('www.', '');
  debugger;
  if (url !== 'localhost:3000') {
    console.log(url);
    debugger;
    if (ENV === 'DEVELOPMENT') {
      let getTenent = url.split('.')[1];
      return { tenantId: getTenent, businessGroupId: getTenent };
    }
    if (ENV === 'PRODUCTION') {
      let getTenent = url.split('.')[0];
      debugger;
      return { tenantId: getTenent, businessGroupId: getTenent };
    }
  }
  const urlParts = url.split('.');
  const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
  const tenantId = urlParts[tenantIndex] || 'rmg';
  const businessGroupId = tenantId || 'rmg'; //! For now
  return { tenantId, businessGroupId };
};
